export const PORTAL_VERSION = "1.4.0";
export const SERVICES = "/services";
export const SECURITY_STATUS = "/security_status";
export const REPORTS = "/reports";
export const REPOSITORY = "/repository";
export const DOMAIN_SECURITY = "/domain_security";
export const SETTINGS = "/settings";
export const LOG_IN = "/log-in";
export const FA2 = "/google-auth";



export const API_LOGIN = "api/login";
export const API_LOGOUT = "api/logout";
export const API_FA2 = "api/fa2";
export const API_GET_USER = "api/get_user";
export const API_PRIVILEGES = "api/privileges";

export const API_SERVICES_DATA = "api/services_data";
export const API_REPORTS_DATA = "api/reports_data";

export const API_CHARTS_DASHBOARD = "api/charts_dashboard";
export const API_CHART_DATA = "api/chart_content_data";

export const API_CLOUD_ATTACHMENT = "/api/file_attachment";
export const API_CLOUD_CREATE_DIRECTORY = "/api/file_create_directory";
export const API_CLOUD_CREATE_FILE = "api/file_upload";
export const API_CLOUD_GET_FIRECTORY_INFO = "api/file_get_firectory_info";


export const DOMAIN_SECURITY_DOMAIN_CHECK = "api/domain_security/check_domain";
export const DOMAIN_SECURITY_LIST_SUBSCRIBED_DOMAIN = "api/domain_security/list_subscribed_domain";
export const DOMAIN_SECURITY_ADD_SUBSCRIBED_DOMAIN = "api/domain_security/add_subscribed_domain";
export const DOMAIN_SECURITY_DELETE_SUBSCRIBED_DOMAIN = "api/domain_security/delete_subscribed_domain";
export const DOMAIN_SECURITY_ALERTS_SERVERS_LIST = "api/domain_security/alerts_list";

export const API_COMPONENTS_MONITOR_DASHBOARD = "api/components_monitor_dashboard";
export const API_COMPONENTS_MONITOR_DATA = "api/components_monitor_content_data";

export const API_GET_SETTINGS = "api/get_settings";
export const UPDATE_AVATAR_SETTINGS = "/api/update_avatar";
export const UPDATE_GENERAL_SETTINGS = "/api/update_general_settings";
export const UPDATE_PASSWORD_SETTINGS = "/api/update_password_settings";
export const UPDATE_NOTIFICATION_SETTINGS = "/api/update_notifications_settings";

export const RECATCHA_TOKEN = "6Lfoc68ZAAAAABE_JPdvTL9KclfRyVwDNoZwn3Ug";

export const TOKEN_OK = 2;
export const TOKEN_FA2 = 1;
export const TOKEN_ERROR = 3;

export const MUTABLE_DIRECTORIES = [
  "/Upload/",
  "Upload/"
];