import React from "react"
import * as Icon from "react-feather"
import { SECURITY_STATUS, REPOSITORY, SERVICES, DOMAIN_SECURITY } from "../constants"


const parts = {
  "DEMISTO_CHARTS": {
    id: "securityStatus",
    title: "Security Status",
    type: "item",
    icon: <Icon.Monitor size={20}/>,
    permissions: ["admin", "editor"],
    navLink: SECURITY_STATUS
  },

  "REPORTS": {
    id: "repository",
    title: "Repository",
    type: "item",
    icon: <Icon.File size={20}/>,
    permissions: ["admin", "editor"],
    navLink: REPOSITORY
  },

  "AUTH_SERVER": {
    id: "home",
    title: "Services",
    type: "item",
    icon: <Icon.Grid size={20}/>,
    permissions: ["admin", "editor"],
    navLink: SERVICES
  },

  "DOMAIN_SECURITY": {
    id: "domainSecurity",
    title: "Domain Security",
    type: "item",
    icon: <Icon.Shield size={20}/>,
    permissions: ["admin", "editor"],
    navLink: DOMAIN_SECURITY
  }
};


class NavigationConfig {
  navigationConfig = [];

  setConfig(array) {
    this.navigationConfig = array.map((entry) => parts[entry]);
  }

  convertToNavConfig(conf) {
    return conf.map((entry) => parts[entry])
  }
}


const navigationConfig = new NavigationConfig();


export default navigationConfig
