import React from "react"
import {Redirect} from "react-router-dom";
import {observable, action, decorate} from "mobx"
import Cookies from 'universal-cookie';
import {
  TOKEN_FA2,
  TOKEN_ERROR,
  TOKEN_OK,
  API_LOGIN,
  API_FA2,
  API_SERVICES_DATA,
  API_REPORTS_DATA,
  API_CHARTS_DASHBOARD,
  API_GET_SETTINGS,
  UPDATE_PASSWORD_SETTINGS,
  UPDATE_GENERAL_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
  API_COMPONENTS_MONITOR_DASHBOARD,
  API_CLOUD_GET_FIRECTORY_INFO,
  API_CLOUD_CREATE_FILE,
  API_CLOUD_CREATE_DIRECTORY,
  API_PRIVILEGES,
  API_GET_USER,
  DOMAIN_SECURITY_DOMAIN_CHECK,
  DOMAIN_SECURITY_LIST_SUBSCRIBED_DOMAIN,
  DOMAIN_SECURITY_ADD_SUBSCRIBED_DOMAIN,
  DOMAIN_SECURITY_DELETE_SUBSCRIBED_DOMAIN,
  DOMAIN_SECURITY_ALERTS_SERVERS_LIST,
  FA2, LOG_IN
} from "./constants";
import navigationConfig from "./configs/navigationConfig";


class Store {
  cookies = new Cookies();

  id = null;
  privileges = [];
  name = "";
  avatar = "";
  company = "";
  entries = {};
  reports = [];
  charts = [];
  componentsMonitor = [];
  settings = {
    "name": "",
    "phone": "",
    "dateOfBirth": 0,
    "notificationNewMessages": true,
    "notificationRequestUpdate": false,
    "notificationNewNews": true,
    "notificationOtherUpdates": true
  };
  HOST = "";

  executeGet(url, process, onError) {
    fetch(`${this.HOST}/${url}`,)
        .then(res => {
          const status = res.status;
          if (status === 200) {
            return res.json();
          } else if (status === 401) {
            this.logout();
          } else {
            onError();
          }
        })
        .then(process, onError)
  }

  executePost(url, body, process, onError) {
    fetch(`${this.HOST}${url}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: body
    })
        .then(res => {
          const status = res.status;
          if (status === 200) {
            return res.json();
          } else if (status === 401) {
            this.logout();
          } else {
            onError();
          }
        })
        .then(process, onError);
  }

  createPostBody(data) {
    let formBody = [];
    data.forEach(entry => {
      formBody.push(`${entry[0]}=${encodeURIComponent(entry[1])}`);
    });
    return formBody.join("&");
  }

  isLogout() {
    const token = this.cookies.get("token");
    if (token === undefined) {
      return TOKEN_ERROR;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const data = JSON.parse(jsonPayload);
    if (data.state === 1) {
      return TOKEN_FA2;
    } else if (data.state === 2) {
      return TOKEN_OK;
    } else {
      return TOKEN_ERROR;
    }
  }

  getTheme() {
    // const token = this.cookies.get("token");
    console.log("Theme");
    const theme = localStorage.getItem('color_theme');
    console.log(theme);
    return theme === null ? "light" : theme;
  }

  setTheme(theme) {
    localStorage.setItem('color_theme', theme);
  }

  getRedirectComponent() {
    const code = this.isLogout();
    if (code === TOKEN_OK)
      return null;
    if (code === TOKEN_FA2)
      return <Redirect to={FA2}/>;
    if (code === TOKEN_ERROR)
      return <Redirect to={LOG_IN}/>;
  }

  updateUserInfo(entry) {
    this.id = entry.id;
    this.name = entry.name;
    this.company = entry.organizationName;
    this.avatar = entry.avatar;
  }
//---------------------------------------------------//

  getServicesData(onSuccess, onError) {
    this.executeGet(
      API_SERVICES_DATA,
      (result) => {
        if (result !== undefined) {
          this.entries = {};
          for (let i = 0; i < result.entries.length; i++) {
            const entry = result.entries[i];
            const category = entry.category;
            if (this.entries[category] === undefined) {
              this.entries[category] = [];
            }
            this.entries[category].push(entry);
          }
          this.updateUserInfo(result.user);
          onSuccess();
        } else {
          onError("Inconsistent result");
        }
      },
      onError
    );
  }

  getReportData(onSuccess, onError) {
    this.executeGet(
      API_REPORTS_DATA,
      (result) => {
        if (result !== undefined) {
          this.reports = [];
          this.reports = result.reports;
          this.updateUserInfo(result.user);
          onSuccess();
        } else {
          onError("Inconsistent result");
        }
      },
      onError
    );
  }

  getChartsData(onSuccess, onError) {
    this.executeGet(
      API_CHARTS_DASHBOARD,
      (result) => {
        if (result !== undefined) {
          this.charts = result.entries;
          this.updateUserInfo(result.user);
          onSuccess();
        } else {
          onError("Inconsistent result");
        }
      },
      onError
    );
  }

  logout(onSuccess, onError) {
    console.log("logout");
    localStorage.removeItem('privileges');
    this.cookies.remove("token", {path: "/", domain: ".m-marianas.io"});
    this.cookies.remove("token");
    this.id = null;
    this.name = null;
    this.entries = {};
    this.reports = [];
    window.location.reload(false);
  }

  auth(email, password, recaptchaToken, onSuccess, onLoginError, onOtherError) {
    this.executeGet(
      `${API_LOGIN}?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&recaptcha=${recaptchaToken}`,
      (result) => {
        if (result !== undefined) {
          if(!result.status) {
            onOtherError(result.message);
          } else {
            this.id = result.data.id;
            this.name = result.data.name;
            onSuccess();
          }
        } else {
          onOtherError("Inconsistent result");
        }
      },
        onOtherError
    );
  }

  fa2(code, onSuccess, onCodeError, onLoginError, onOtherError) {
    this.executeGet(
      `${API_FA2}?code=${code}`,
        (result) => {
          if (result !== undefined) {
            this.id = result.data.id;
            this.name = result.data.name;
            onSuccess();
          } else {
            onOtherError("Inconsistent result");
          }
        },
      onCodeError
    );
  }

  getPrivileges(onSuccess, onError) {
    const privileges = localStorage.getItem('privileges');
    if(privileges != null) {
      this.privileges = JSON.parse(privileges);
      navigationConfig.setConfig(this.privileges);
      onSuccess(this.privileges);
    }

    this.executeGet(
        `${API_PRIVILEGES}`,
        (result) => {
          if (result !== undefined) {
            if(result.status) {
              this.privileges = result.data.privileges;
              localStorage.setItem('privileges', JSON.stringify(this.privileges));
              navigationConfig.setConfig(this.privileges);
              onSuccess(this.privileges);
            } else {
              onError("Inconsistent result");
            }
          }
        },
        onError
    );
  }

  getUser(onSuccess, onError) {
    this.executeGet(
        `${API_GET_USER}`,
        (result) => {
          if (result !== undefined) {
            if(result.status) {
              this.updateUserInfo(result.data);
              onSuccess();
            } else {
              onError("Inconsistent result");
            }
          }
        },
        onError
    );
  }

  // Setting part
  getSettingsData(onSuccess, onError) {
    this.executeGet(
      API_GET_SETTINGS,
      (result) => {
        if (result !== undefined && result.status) {
          console.log(result.settings);
          this.settings = result.settings;
          this.updateUserInfo(result.settings);
          onSuccess();
        } else {
          onError("Inconsistent result");
        }
      },
      onError
    );
  }

  changeSettingsValue(name, value) {
    this.settings[name] = value;
  }

  updatePassword(oldPassword, newPassword, repeatPassword, onSuccess, onAuthError, onOtherError) {
    const formBody = this.createPostBody([
      ["oldPassword", oldPassword],
      ["newPassword", newPassword],
      ["repeatPassword", repeatPassword]
    ]);

    this.executePost(
      UPDATE_PASSWORD_SETTINGS,
      formBody,
      (result) => {
        if (result !== undefined) {
          if(!result.status) {
            onOtherError(result.message);
          } else {
            onSuccess();
          }
        }
      },
        onOtherError
    );
  }

  updateGeneralSettings(onSuccess, onAuthError, onOtherError) {
    const formBody = this.createPostBody([
      ["name", this.settings.name],
      ["phone", this.settings.phone]
    ]);

    this.executePost(
        UPDATE_GENERAL_SETTINGS,
        formBody,
        (result) => {
          if (result !== undefined) {
            if(!result.status) {
              onOtherError(result.message);
            } else {
              onSuccess();
            }
          }
        },
        onOtherError
    );
  }

  updateNotificationSettings(onSuccess, onAuthError, onOtherError) {
    const formBody = this.createPostBody([
      ["notificationNewMessages", this.settings.notificationNewMessages],
      ["notificationRequestUpdate", this.settings.notificationRequestUpdate],
      ["notificationNewNews", this.settings.notificationNewNews],
      ["notificationOtherUpdates", this.settings.notificationOtherUpdates]
    ]);

    this.executePost(
        UPDATE_NOTIFICATION_SETTINGS,
        formBody,
        (result) => {
          if (result !== undefined) {
            if(!result.status) {
              onOtherError(result.message);
            } else {
              onSuccess();
            }
          }
        },
        onOtherError
    );
  }

  //
  getComponentsMonitorData(onSuccess, onError) {
    this.executeGet(
        API_COMPONENTS_MONITOR_DASHBOARD,
        (result) => {
          if (result !== undefined) {
            this.componentsMonitor = result.entries;
            this.updateUserInfo(result.user);
            onSuccess();
          } else {
            onError("Inconsistent result");
          }
        },
        onError
    );
  }


  // Office cloud part
  getDirectoryContent(path, onSuccess, onError) {
    this.executeGet(
        `${API_CLOUD_GET_FIRECTORY_INFO}?path=${path}`,
        (result) => {
          if (result !== undefined && result.status) {
            this.updateUserInfo(result.user);
            onSuccess(result.data);
          } else {
            onError("Inconsistent result");
          }
        },
        onError
    );
  }

  createDirectory(path, onSuccess, onError) {
    const formBody = this.createPostBody([
      ["path", path]
    ]);
    console.log(path);

    this.executePost(
        API_CLOUD_CREATE_DIRECTORY,
        formBody,
        (result) => {
          if (result !== undefined) {
            if(!result.status) {
              onError(result.message);
            } else {
              onSuccess();
            }
          }
        },
        onError
    );
  }

  uploadFile(fileData, filePath, fileName, onProgress, onSuccess, onError) {
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('path', filePath);
    formData.append('name', fileName);

    let request = new XMLHttpRequest();
    request.open('POST', `${this.HOST}/${API_CLOUD_CREATE_FILE}`);
    request.upload.addEventListener('progress', function(e) {
      let percent_completed = (e.loaded / e.total)*100;
      onProgress(percent_completed);
    });
    request.addEventListener('load', function(e) {
      const result = JSON.parse(request.response);
      console.log("READY");
      console.log(result);
      if(result.status) {
        onSuccess(result.data)
      } else {
        onError(result.message)
      }

    });
    request.send(formData);
  }

  // Domain security part
  checkDomainSecurity(domain, onSuccess, onError) {
    this.executeGet(
        `${DOMAIN_SECURITY_DOMAIN_CHECK}?domain=${domain}`,
        (result) => {
          if (result !== undefined) {
            if (result.status) {
              onSuccess(result.data);
            } else {
              onError(result.message);
            }
          }
        },
        onError
    );
  }

  getListSubscribedDomain(onSuccess, onError) {
    this.executeGet(
        `${DOMAIN_SECURITY_LIST_SUBSCRIBED_DOMAIN}`,
        (result) => {
          if (result !== undefined) {
            if(result.status) {
              onSuccess(result.data);
            } else {
              onError(result.message);
            }
          }
        },
        onError
    );
  }

  addSubscribedDomain(domain, onSuccess, onError) {
    const formBody = this.createPostBody([
      ["domain", domain]
    ]);

    this.executePost(
        DOMAIN_SECURITY_ADD_SUBSCRIBED_DOMAIN,
        formBody,
        (result) => {
          if (result !== undefined) {
            if(!result.status) {
              onError(result.message);
            } else {
              onSuccess();
            }
          }
        },
        onError
    );
  }

  deleteSubscribedDomain(domain, onSuccess, onError) {
    const formBody = this.createPostBody([
      ["domain", domain]
    ]);

    this.executePost(
        DOMAIN_SECURITY_DELETE_SUBSCRIBED_DOMAIN,
        formBody,
        (result) => {
          if (result !== undefined) {
            if(!result.status) {
              onError(result.message);
            } else {
              onSuccess();
            }
          }
        },
        onError
    );
  }

  getAlertsList(onSuccess, onError) {
    this.executeGet(
        `${DOMAIN_SECURITY_ALERTS_SERVERS_LIST}`,
        (result) => {
          if (result !== undefined) {
            if(result.status) {
              onSuccess(result.data);
            } else {
              onError(result.message);
            }
          }
        },
        onError
    );

  }
}

decorate(Store, {
  id: observable,
  name: observable,
  entries: observable,
  reports: observable,
  company: observable,
  avatar: observable,
  settings: observable,

  // isLogout: computed,

  getHomeData: action,
  getReportData: action,
  logout: action,
  auth: action,
  fa2: action,
  getDirectoryContent: action,

  getSettingsData: action,
  changeSettingsValue: action,

});

const store = new Store();

export default store