import React, {PureComponent} from "react"
import {
  Navbar,
  NavItem,
  NavLink
} from "reactstrap"
import * as Icon from "react-feather"
import classnames from "classnames"
import NavbarUser from "./NavbarUser"
import store from "../../../store"
import {observer} from "mobx-react/dist/index";

const ThemeNavbar = observer(class ThemeNavbar extends PureComponent {
  colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  navbarTypes = ["floating", "static", "sticky", "hidden"];

  render() {
    return (
        <React.Fragment>
          <div className="content-overlay"/>
          <div className="header-navbar-shadow"/>
          <Navbar
              className={classnames(
                  "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
                  {
                    "navbar-light": this.props.navbarColor === "default" || !this.colorsArr.includes(this.props.navbarColor),
                    "navbar-dark": this.colorsArr.includes(this.props.navbarColor),
                    "bg-primary":
                    this.props.navbarColor === "primary" && this.props.navbarType !== "static",
                    "bg-danger":
                    this.props.navbarColor === "danger" && this.props.navbarType !== "static",
                    "bg-success":
                    this.props.navbarColor === "success" && this.props.navbarType !== "static",
                    "bg-info":
                    this.props.navbarColor === "info" && this.props.navbarType !== "static",
                    "bg-warning":
                    this.props.navbarColor === "warning" && this.props.navbarType !== "static",
                    "bg-dark":
                    this.props.navbarColor === "dark" && this.props.navbarType !== "static",
                    "d-none": this.props.navbarType === "hidden" && !this.props.horizontal,
                    "floating-nav":
                    (this.props.navbarType === "floating" && !this.props.horizontal) || (!this.navbarTypes.includes(this.props.navbarType) && !this.props.horizontal),
                    "navbar-static-top":
                    this.props.navbarType === "static" && !this.props.horizontal,
                    "fixed-top": this.props.navbarType === "sticky" || this.props.horizontal,
                    "scrolling": this.props.horizontal && this.props.scrolling

                  }
              )}
          >
            <div className="navbar-wrapper">
              <div className="navbar-container content">
                <div
                    className="navbar-collapse d-flex justify-content-between align-items-center"
                    id="navbar-mobile"
                >
                  <div className="bookmark-wrapper">

                    <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                      <ul className="navbar-nav d-xl-none">
                        <NavItem className="mobile-menu mr-auto">
                          <NavLink
                              className="nav-menu-main menu-toggle hidden-xs is-active"
                              onClick={this.props.sidebarVisibility}>
                            <Icon.Menu className="ficon"/>
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                  </div>
                  <NavbarUser
                      handleAppOverlay={this.props.handleAppOverlay}
                      changeCurrentLang={this.props.changeCurrentLang}
                      company={store.company}
                      userName={store.name}
                      userImg={store.avatar}
                  />
                </div>
              </div>
            </div>
          </Navbar>
        </React.Fragment>
    )
  }
})


export default ThemeNavbar
